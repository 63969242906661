<template>
  <div id="login">
    <el-container>
      <div class="main">
        <div class="item-l-r">
          <div class="item-text-panel">
            <img src="@/assets/login/bantext.png" />
            <!-- 登录窗口  -->
            <div v-show="Isshow" class="login-box">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="密码登录" name="first">
                  <div class="phone_name">
                    <el-input v-model="input1" maxlength="11" placeholder="请输入手机号"></el-input>
                  </div>
                  <div class="password">
                    <el-input
                      v-model="input2"
                      placeholder="请输入密码"
                      show-password
                      @keyup.enter.native="loginAccount"
                    ></el-input>
                  </div>
                  <div class="box_warp">
                    <span @click="registbox()">新用户注册</span>
                    <!-- <span>忘记密码</span> -->
                  </div>
                  <div class="login" @click="loginAccount">
                    <span>登 录</span>
                  </div>
                  <div class="other">
                    <el-divider>其他方式登录</el-divider>
                  </div>
                  <div class="wechatqq">
                    <div class="wechat">
                      <img src="@/assets/login/wechat_icon.png" alt="" />
                    </div>
                    <div class="qq">
                      <img src="@/assets/login/qq_icon.png" alt="" />
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="短信登录" name="second">
                  <div class="phone_name">
                    <el-input v-model="input3" placeholder="请输入手机号"></el-input>
                  </div>
                  <div class="code_yzm">
                    <div class="passcode">
                      <el-input
                        v-model="input4"
                        placeholder="请输入验证码"
                        @keyup.enter.native="loginverifycode"
                      ></el-input>
                    </div>
                    <el-button
                      class="yzm"
                      type="primary"
                      :disabled="disabled"
                      @click="verifyCode"
                      >{{ btntxt }}</el-button
                    >
                  </div>
                  <div class="box_warp">
                    <span @click="registbox()">新用户注册</span>
                    <!-- <span>忘记密码</span> -->
                  </div>
                  <div class="login" @click="loginverifyCode">
                    <span>登 录</span>
                  </div>
                  <div class="other">
                    <el-divider>其他方式登录</el-divider>
                  </div>
                  <div class="wechatqq">
                    <div class="wechat">
                      <img src="@/assets/login/wechat_icon.png" alt="" />
                    </div>
                    <div class="qq">
                      <img src="@/assets/login/qq_icon.png" alt="" />
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
            <!-- 注册窗口 -->
            <div v-show="IsShow" class="regist-box">
              <span class="title">注&nbsp;&nbsp;册</span>
              <div class="phone_name">
                <el-input v-model="phone" placeholder="请输入11位手机号">
                  <template slot="prepend">+86</template>
                </el-input>
              </div>
              <div class="code_yzm">
                <div class="passcode">
                  <el-input v-model="phoneVerifyCode" placeholder="请输入验证码"></el-input>
                </div>
                <el-button class="yzm" type="primary" :disabled="disabled1" @click="verifyCodes">{{
                  btntxt1
                }}</el-button>
              </div>
              <div class="password">
                <el-input
                  v-model="password"
                  placeholder="6-16位英文和数字密码"
                  show-password
                ></el-input>
              </div>
              <div class="password">
                <el-input
                  v-model="confirmPassword"
                  placeholder="请再次输入密码"
                  show-password
                ></el-input>
              </div>
              <div class="login" @click="register">注 册</div>
              <p class="tip" @click="loginbox">使用已有帐号登录</p>
            </div>
          </div>
        </div>
        <!-- 登录后显示的面板 -->
        <div v-show="IsAct" id="IsAct" class="items-log">
          <div class="logined-box">
            <p class="im-1">尊敬的用户：{{ loginName }}，欢迎您使用融e学平台。</p>
            <p class="im-2">如果您想在平台发布您的课程请先认证公共学堂</p>
            <div class="items-cr">
              <p class="im-3">创建公共学堂</p>
              <div class="items-nx">
                <el-input
                  v-model="collegeName"
                  placeholder="您的学堂名称"
                  class="items-in"
                ></el-input>
                <span @click="gocollege">免费使用</span>
              </div>
              <div class="items-dx">
                <div class="items-cent">
                  <div v-for="(item, index) in List" :key="index" class="items-oo">
                    <div class="lvse"></div>
                    <p>{{ item.name }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hd">
          <img src="@/assets/login/Pic-2.png" class="imgs" />
        </div>
        <div class="items-zs">
          <img src="@/assets/login/Pic-3.png" class="imgs" />
        </div>
        <div class="items-xy">
          <img src="@/assets/login/Pic-4.png" class="imgs" />
        </div>
        <!-- 底部 -->
        <div class="footer">
          <div class="foot">
            <div class="foot_m">
              <div class="item-msd">
                <p class="font-Comp">武汉市中潮互联网教育有限公司</p>
                <div class="items-jh">
                  <img src="@/assets/login/Pic-msd.jpg" />
                  <div>
                    <p class="font-Dec">明师道——重构知识体系 引领师道新潮流</p>
                    <p class="font-So">发布您的优秀课程，拥有千万学子，完成传道授业解惑</p>
                  </div>
                </div>
              </div>
              <div class="item-ewm">
                <img src="@/assets/login/msd-ewm.jpg" />
                <p>明师道小程序二维码</p>
              </div>
              <div class="item-qt">
                <div class="items-jhs">
                  <img src="@/assets/login/Pic-mzd.jpg" />
                  <div>
                    <p class="font-Dec">明职道——弘扬职业精神 打造职道竞争力</p>
                    <p class="font-So">过千门各行各业的视频课程，助您职场竞争力提升MAX</p>
                  </div>
                </div>
                <div class="items-jhp">
                  <img src="@/assets/login/Pic-mhd.jpg" />
                  <div>
                    <p class="font-Dec">明商道——创建企业大学，占领商道制高点</p>
                    <p class="font-So">全方位一站式内培平台，搭建持续学习能力提升的生态系统</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="foot-n">
              <p class="help">帮助手册<span>使用协议</span><span>隐私条款</span></p>
              <a href="https://beian.miit.gov.cn/#/Integrated/index">
                Copyright ©2015-2019 版权所有武汉市中潮互联网教育有限公司
                鄂ICP备15017598号-1（中潮教育融e学）
              </a>
              <p class="Powerby">Powered by 中潮互联网教育</p>
            </div>
          </div>
        </div>
      </div>
    </el-container>
  </div>
</template>
<script>
import {
  login,
  verifycode,
  loginverifycode,
  register,
  myorg,
  orgChange,
  userAuthQuery,
} from '@/api/login'
import { setCookie, getCookie } from '@/utils/cookies'
import to from 'await-to'
export default {
  name: 'Login',
  data() {
    return {
      disabled: false, //发送验证码是否可点
      time: 60, //倒计时间
      btntxt: '发送验证码',
      disabled1: false, //注册面板发送验证码是否可点
      time1: 60, //注册面板倒计时间
      btntxt1: '发送验证码',
      Isshow: true, //登录面板
      IsShow: false, //注册面板
      IsAct: false, //登录后显示的面板
      loginName: '', //登录后用户名
      activeName: 'first',
      input1: '',
      input2: '',
      input3: '',
      input4: '',
      phone: '',
      phoneVerifyCode: '',
      password: '',
      confirmPassword: '',
      List: [
        { name: '平台免费使用' },
        { name: '一站式线上培训管理' },
        { name: '全方位客服服务' },
        { name: '多端协助销售' },
        { name: '智能报表分析' },
      ],
      collegeName: '',
    }
  },
  created() {},
  methods: {
    handleClick() {},
    registbox() {
      this.Isshow = false
      this.IsShow = true
    },
    loginbox() {
      this.Isshow = true
      this.IsShow = false
    },
    gocollege() {
      if (this.collegeName == '') {
        this.$message.error('请输入学堂名称~')
        return false
      }
      this.$router.push({
        path: '/create',
        query: { Collegename: this.collegeName },
      })
    },
    async loginAccount() {
      if (this.input1 == '') {
        this.$message({
          message: '请填写帐号',
          type: 'warning',
        })
        return false
      }
      if (this.input2 == '') {
        this.$message({
          message: '请填写密码',
          type: 'warning',
        })
        return false
      } else {
        //帐号密码登录
        const [res, err] = await to(login({ account: this.input1, password: this.input2 }))
        if (err) return this.$message.warning(err.msg)
        setCookie('myToken', res.data.token) //token
        setCookie('userInfo', res.data)
        setCookie('headPic', res.data.headPic) //头像
        setCookie('nickName', res.data.nickName) //昵称
        setCookie('userName', res.data.userName) //用户姓名
        setCookie('email', res.data.email) //邮箱
        setCookie('sex', res.data.sex) //性别
        setCookie('phone', res.data.phone) //用户手机号
        this.$message({
          message: '登录成功',
          type: 'success',
        })
        setTimeout(() => {
          this.handleUserHasOrg()
        }, 1000)
      }
    },
    handleUserHasOrg() {
      this.handleUserAuthority()
      myorg({
        current: 1,
        size: 100,
      }).then(res => {
        if (res.msg == 'success') {
          var hasOrg = res.data.total > 0
          setCookie('hasOrg', hasOrg) //是否有机构
          setTimeout(() => {
            var realAuth = JSON.parse(getCookie('SupplyrealAuth'))
            if (hasOrg == false) {
              this.Isshow = false
              this.IsShow = false
              this.IsAct = true
              this.loginName = res.data.phone
              this.$nextTick(() => {
                document
                  .getElementById('IsAct')
                  .scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' })
              })
            } else if (hasOrg == true && realAuth == true) {
              orgChange({
                organizationId: res.data.list[0].organizationId,
              }).then(res => {
                if (res.msg == 'success') {
                  this.$router.push(`/dashbord`)
                }
              })
            } else if (hasOrg == true && realAuth == false) {
              orgChange({
                organizationId: res.data.list[0].organizationId,
              }).then(res => {
                if (res.msg == 'success') {
                  this.$router.push(`/userauth`)
                }
              })
            }
          }, 1000)
        }
      })
    },
    async handleUserAuthority() {
      const [res, err] = await to(userAuthQuery({}))
      if (err) return this.$message.warning(err.msg)
      setCookie('SupplyrealAuth', res.data.realNameAuth) //是否实名认证
    },
    //获取登陆验证码
    verifyCode() {
      if (this.input3 == '') {
        this.$message({
          message: '请填写11位手机号',
          type: 'warning',
        })
        return false
      }
      verifycode({
        phone: this.input3,
      }).then(res => {
        if (res.msg == 'success') {
          this.$message({
            message: '获取验证码成功，请在手机上查看',
            type: 'success',
          })
          this.time = 60
          this.timer()
        } else {
          this.$message({
            message: res.msg,
            type: 'warning',
          })
        }
      })
    },
    //倒计时
    timer() {
      if (this.time > 0) {
        this.disabled = true
        this.time--
        this.btntxt = this.time + '秒'
        setTimeout(this.timer, 1000)
      } else {
        this.time = 0
        this.btntxt = '发送验证码'
        this.disabled = false
      }
    },
    //手机验证码登录
    async loginverifyCode() {
      if (this.input3 == '') {
        this.$message({
          message: '请填写手机号',
          type: 'warning',
        })
        return false
      }
      if (this.input4 == '') {
        this.$message({
          message: '请填写验证码',
          type: 'warning',
        })
        return false
      } else {
        const [res, err] = await to(
          loginverifycode({ phone: this.input3, verifyCode: this.input4 }),
        )
        if (err) return this.$message.warning(err.msg)
        setCookie('myToken', res.data.token) //token
        setCookie('userInfo', res.data)
        setCookie('headPic', res.data.headPic) //头像
        setCookie('nickName', res.data.nickName) //昵称
        setCookie('userName', res.data.userName) //用户姓名
        setCookie('email', res.data.email) //邮箱
        setCookie('sex', res.data.sex) //性别
        setCookie('phone', res.data.phone) //用户手机号
        this.$message({
          message: '登录成功',
          type: 'success',
        })
        setTimeout(() => {
          this.handleUserHasOrg()
        }, 1000)
      }
    },
    //注册获取手机验证码
    verifyCodes() {
      if (this.phone == '') {
        this.$message({
          message: '请填写11位手机号',
          type: 'warning',
        })
        return
      }
      verifycode({
        phone: this.phone,
      }).then(res => {
        if (res.msg == 'success') {
          this.$message({
            message: '获取验证码成功，请在手机上查看',
            type: 'success',
          })
          this.time1 = 60
          this.regtimer()
        } else {
          this.$message({
            message: res.msg,
            type: 'warning',
          })
        }
      })
    },
    //注册面板倒计时
    regtimer() {
      if (this.time1 > 0) {
        this.disabled1 = true
        this.time1--
        this.btntxt1 = this.time1 + '秒'
        setTimeout(this.regtimer, 1000)
      } else {
        this.time1 = 0
        this.btntxt1 = '发送验证码'
        this.disabled1 = false
      }
    },
    //注册事件
    async register() {
      if (this.phone == '') {
        this.$message({
          message: '请输入手机号',
          type: 'warning',
        })
        return
      }
      if (this.phoneVerifyCode == '') {
        this.$message({
          message: '请输入验证码',
          type: 'warning',
        })
        return
      }
      if (this.password == '') {
        this.$message({
          message: '请输入密码',
          type: 'warning',
        })
        return
      }
      if (this.confirmPassword == '') {
        this.$message({
          message: '请输入确认密码',
          type: 'warning',
        })
        return
      }
      if (this.password != this.confirmPassword) {
        this.$message({
          message: '两次密码不一致，请确认！',
          type: 'warning',
        })
        return
      }
      const [, err] = await to(
        register({
          phone: this.phone,
          phoneVerifyCode: this.phoneVerifyCode,
          password: this.password,
          confirmPassword: this.confirmPassword,
        }),
      )
      if (err) return this.$message.warning(err.msg)
      this.IsShow = false
      this.Isshow = true
      this.$message({
        message: '注册成功请登录',
        type: 'success',
      })
    },
  },
}
</script>
<style scoped>
.main {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: default;
}
.item-l-r {
  width: 100%;
  height: 760px;
  background-image: url('../../assets/login/bj.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
}
.item-text-panel {
  margin-top: 150px;
  display: flex;
  justify-content: space-between;
}
.item-text-panel img {
  width: 685px;
  height: 338px;
  margin-top: 70px;
  margin-right: 109px;
}
.imgs {
  width: 100%;
  display: block;
  position: relative;
}
.login-box {
  width: 426px;
  height: 482px;
  background: #f8f8f8;
  border-radius: 10px;
}
.login-box >>> .el-tabs__nav {
  margin: 20px 20px 0 20px;
}
.login-box >>> .el-tabs__item {
  width: 193px;
  padding: 0;
  height: 80px;
  display: inline-block;
  list-style: none;
  font-size: 24px;
  text-align: center;
  font-weight: 700;
  color: #999999;
  position: relative;
  line-height: 80px;
}
.login-box >>> .el-input__inner {
  background-color: #f8f8f8;
  background-image: none;
  border-radius: 0px;
  border: 0;
  border-bottom: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  width: 346px;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  width: 100%;
}
.login-box >>> .el-tabs__active-bar {
  height: 4px;
  background-color: #f8f8f8;
}
.login-box >>> .el-tabs__nav-wrap::after {
  background-color: #f8f8f8;
}
.login-box >>> .el-tabs__item.is-active {
  color: #ff7b33;
}
.phone_name {
  margin-top: 10px;
  margin-left: 40px;
  margin-right: 40px;
}
.password {
  margin-top: 20px;
  margin-left: 40px;
  margin-right: 40px;
}
.box_warp {
  display: flex;
  justify-content: space-between;
  margin: 0 40px;
  padding: 0 20px;
  font-size: 14px;
  color: #ff7b33;
}
.box_warp span {
  line-height: 30px;
  cursor: pointer;
}
.login {
  height: 56px;
  text-align: center;
  line-height: 50px;
  background: #ff7b33;
  border-radius: 21px;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
  margin: 34px 40px 0 40px;
}
.other {
  width: 320px;
  margin: 30px auto 0;
}
.el-divider__text {
  color: #909399;
  background: #f8f8f8;
}
.wechatqq {
  width: 100px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.wechatqq div {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.wechatqq div img {
  max-width: 100%;
}
.code_yzm {
  margin: 20px 40px 0 40px;
  display: flex;
  justify-content: space-between;
}
.passcode {
  width: 250px;
}
.yzm {
  width: 140px;
  height: 40px;
}
.hd {
  margin-top: 30px;
}
.items-zs {
  margin-top: 30px;
}
.items-xy {
  margin-top: 30px;
  font-size: 0;
}
.item-ty {
  width: 100%;
  height: 320px;
  background-image: url('../../assets/login/bc.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.item-ty-fir {
  text-align: center;
  font-size: 28px;
  font-family: Source Han Sans CN, Source Han Sans CN-Medium;
  font-weight: 500;
  color: #f4e7e7;
  padding-top: 87px;
}
.item-ty-sec {
  text-align: center;
  font-size: 28px;
  font-family: Source Han Sans CN, Source Han Sans CN-Medium;
  font-weight: 500;
  color: #f4e7e7;
  line-height: 30px;
}
.item-ty span {
  display: block;
  text-align: center;
  width: 200px;
  height: 50px;
  background: #ffffff;
  border-radius: 10px;
  font-size: 28px;
  font-family: Source Han Sans CN, Source Han Sans CN-Medium;
  font-weight: 500;
  color: #ff7b33;
  margin: 42px 790px 0 790px;
  cursor: pointer;
}

.footer {
  height: 360px;
  background: #333333;
  display: flex;
  justify-content: center;
}
.footer a {
  color: #666;
}
.foot_m {
  display: flex;
  padding-top: 40px;
}
.item-msd {
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: left;
}
.font-Comp {
  font-size: 21px;
  color: #ffffff;
  line-height: 43px;
}
.font-Dec {
  font-size: 18px;
  color: #ffffff;
  line-height: 36px;
}
.font-So {
  font-size: 14px;
  color: #bdbdbd;
  line-height: 36px;
}
.items-jh {
  margin-top: 50px;
  display: flex;
}
.items-jh img {
  width: 79px;
  height: 79px;
  margin-right: 16px;
}
.item-ewm {
  margin-left: 25px;
  margin-top: 23px;
}
.item-ewm img {
  width: 126px;
  height: 126px;
}
.item-ewm p {
  text-align: center;
  font-size: 14px;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  color: #bdbdbd;
  line-height: 36px;
}
.item-qt {
  margin-left: 78px;
}
.items-jhs {
  display: flex;
}
.items-jhs img {
  width: 79px;
  height: 79px;
  margin-right: 16px;
}
.items-jhp {
  display: flex;
  margin-top: 25px;
}
.items-jhp img {
  width: 79px;
  height: 79px;
  margin-right: 16px;
}
.foot-n {
  margin-top: 50px;
  text-align: center;
}
.help {
  font-size: 12px;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  color: #bdbdbd;
}
.help span {
  margin-left: 63px;
}
.foot-n a {
  display: block;
  text-decoration: none;
  font-size: 12px;
  margin-top: 5px;
}
.Powerby {
  font-size: 12px;
  color: #666;
  text-align: center;
  margin-top: 5px;
}
.regist-box {
  width: 426px;
  height: 482px;
  background: #f8f8f8;
  border-radius: 10px;
}
.title {
  display: block;
  font-size: 24px;
  font-family: Source Han Sans CN, Source Han Sans CN-Bold;
  font-weight: 700;
  text-align: center;
  color: #333333;
  margin-top: 50px;
}
.regist-box >>> .el-input-group__prepend {
  border: 0;
  border-bottom: 1px solid #dcdfe6;
  background-color: #f8f8f8;
  color: #333333;
}
.regist-box >>> .el-input__inner {
  background-color: #f8f8f8;
  background-image: none;
  border-radius: 0px;
  border: 0;
  border-bottom: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  width: 100%;
}
.tip {
  margin-top: 20px;
  font-size: 14px;
  font-family: Source Han Sans CN, Source Han Sans CN-Bold;
  font-weight: 700;
  text-align: center;
  color: #ff7b33;
  line-height: 20px;
  cursor: pointer;
}
.items-log {
  margin-top: 60px;
  display: flex;
  justify-content: center;
}
.logined-box {
  width: 1200px;
  height: 388px;
  background: #ff7b33;
}
.im-1 {
  font-size: 24px;
  font-family: Source Han Sans CN, Source Han Sans CN-Medium;
  font-weight: 500;
  text-align: center;
  color: #fff;
  margin-top: 20px;
  margin-left: 10px;
}
.im-2 {
  font-size: 24px;
  font-family: Source Han Sans CN, Source Han Sans CN-Medium;
  font-weight: 500;
  text-align: center;
  color: #fff;
  margin-left: 10px;
}
.items-cr {
  width: 1120px;
  height: 230px;
  background: #fff;
  margin: 26px 40px 33px 40px;
}
.im-3 {
  font-size: 30px;
  font-family: Source Han Sans CN, Source Han Sans CN-Medium;
  font-weight: 600;
  text-align: center;
  color: #333;
  padding-top: 22px;
}
.items-nx >>> .el-input__inner {
  height: 36px;
  width: 413px;
}
.items-in {
  height: 36px;
  width: 413px;
}
.items-nx {
  display: flex;
  justify-content: center;
  margin-top: 23px;
}
.items-nx span {
  display: inline-block;
  width: 128px;
  height: 36px;
  background: linear-gradient(180deg, #ffa675 0%, #ff7b33 100%);
  text-align: center;
  color: #fff;
  font-size: 18px;
  border-radius: 4px;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  line-height: 36px;
  cursor: pointer;
  margin-left: 20px;
}
.items-dx {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.items-cent {
  width: 550px;
  display: flex;
  box-sizing: border-box;
  flex-flow: wrap;
}
.items-oo {
  display: flex;
  justify-content: space-between;
  width: auto;
  margin-right: 30px;
  margin-bottom: 10px;
}
.items-oo p {
  font-size: 14px;
  margin-left: 5px;
}
.lvse {
  width: 8px;
  height: 8px;
  background: #409eff;
  border-radius: 50px;
  margin-top: 6px;
}
</style>
